<template>
  <div>
    <div class="loader" v-if="!loaded"></div>
    <section v-if="!isSended && loaded" class="steps">
      <div class="align-content" v-if="error">
        <b-message type="is-danger" has-icon>
          <strong>При загрузке возникла ошибка!</strong>
          <br/>
          {{error}}
        </b-message>
      </div>
      <div class="columns" v-else>
        <div class="column is-two-thirds">
          <b-steps
            v-model="activeStep"
            :animated="false"
          >
            <b-step-item step="1" label="Сумма">
              <section class="step">
                <div class='content'>
                  <b-field class="head-select" horizontal v-if="programs.length > 1" label="Банковский продукт">
                    <b-select
                        v-model="program">
                      <option v-for="program in programs" :key="program.id" :value="program">
                        {{program.type | toLabel}}
                      </option>
                    </b-select>
                  </b-field>
                  <div class='main'>
                    <b-field label="Cтоимость товара">
                      {{totalSum | numFormat}} руб.
                    </b-field>
                    <div class="columns ">
                      <b-field :label="'Срок ' + typeLabel" class='column wraps'>
                        <b-radio-button
                            v-for="monthly in monthlyPeriods"
                            :key="monthly"
                            v-model="period"
                            size="is-small"
                            :native-value="monthly">
                          {{monthly}}
                        </b-radio-button>
                      </b-field>
                      <b-field label="Ежемесячный платеж" class='column'>
                        <h4><span>{{paymentSum | numFormat }} руб.</span></h4>
                      </b-field>
                    </div>
                    <small class="desc">Пример расчета ежемесячного платежа носит исключительно информационный характер и не является публичной офертой</small>
                  </div>
                </div>
                <div class='content'>
                  <h1 class="title">Контактная информация</h1>
                  <div class="contacts">
                    <b-field
                        :type="$v.name.$error ? 'is-danger' : ''"
                        :message="$v.name.$error ? 'Поле необходимо заполнить!' : 'Обязательно для заполнения'"
                      >
                      <b-input type="text"  placeholder="Фамилия, имя, отчество*" v-model="name"></b-input>
                    </b-field>
                  </div>
                  <div class="contacts columns">
                    <div class="column">
                      <b-field
                          :type="$v.phone.$error ? 'is-danger' : ''"
                          :message="$v.phone.$error ? 'Поле необходимо заполнить!' : 'Обязательно для заполнения'"
                        >
                        <masked-input class='input' v-model="phone" mask="\+\7 (111) 111-1111" placeholder="Мобильный номер*" />
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field :type="$v.email.$error ? 'is-danger' : ''">
                        <b-input type="email" placeholder="Электронная почта" v-model="email"></b-input>
                      </b-field>
                    </div>
                  </div>
                  <div class="contacts">
                    <b-field>
                      <b-checkbox v-model="assignOffert">
                        Даю согласие на обработку персональных данных.
                        <a href="https://sbkcredit.ru/politika" target="_blank">Подробнее тут.</a>
                      </b-checkbox>
                    </b-field>
                  </div>
                </div>
              </section>
            </b-step-item>
            <!--b-step-item
              step="2"
              label="Подтверждение телефона"
            >
              <section class="step">
                <div class="title">{{name}}, подтвердите номер телефона</div>
                <div class='padding2'>
                  <strong>{{name}}</strong>, вы уже заполнили заявку, теперь Вам необходимо подтвердить номер
                  телефона. Чтобы это сделать введите отправленный Вам на мобильный номер <i>{{phone}}</i>
                  код в поле ниже:
                  <br/>
                  <br/>
                  <div class="sms-code">
                    <b-field
                        label="Код из СМС"
                        :type="smsCodeValid ? 'is-danger' : ''"
                      >
                      <masked-input class="input" v-model="smsCode" mask="1111" placeholder="••••" @input="checkCode($event)"/>
                    </b-field>
                  </div>
                </div>
              </section>
            </b-step-item-->
            <b-step-item
              step="2"
              label="Паспортные данные"
            >
              <section class="step">
                <div class="title">Заполните паспортные данные</div>
                <div class='content'>
                  <div class="columns">
                    <div class="column">
                      <b-field :type="$v.passport.$error ? 'is-danger' : ''">
                        <masked-input class="input" v-model="passport" mask="1111 111111" placeholder="Серия и номер*" @input="checkCode($event)"/>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field :type="$v.birthday.$error ? 'is-danger' : ''">
                        <b-datepicker
                          v-model="birthday"
                          placeholder="Дата рождения*"
                          icon="calendar-today"
                          trap-focus>
                        </b-datepicker>
                      </b-field>
                    </div>
                  </div>
                  <b-field :type="$v.birthplace.$error ? 'is-danger' : ''">
                    <b-input placeholder="Место рождения*" v-model="birthplace"></b-input>
                  </b-field>
                  <div class="columns">
                    <div class="column">
                      <b-field :type="$v.code.$error ? 'is-danger' : ''">
                        <div class="control">
                          <masked-input class="input" v-model="code" mask="111-111" placeholder="Код подразделения*" @input="checkCode($event)"/>
                        </div>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field :type="$v.insureDate.$error ? 'is-danger' : ''">
                        <b-datepicker
                          v-model="insureDate"
                          placeholder="Дата выдачи*"
                          icon="calendar-today"
                          trap-focus>
                        </b-datepicker>
                      </b-field>
                    </div>
                  </div>
                  <b-field :type="$v.insurer.$error ? 'is-danger' : ''">
                    <b-input placeholder="Кем выдано*" v-model="insurer"></b-input>
                  </b-field>
                </div>
                <div class="title">Адрес постоянной регистрации</div>
                <div class='content'>
                  <b-field :type="$v.addressReg.$error ? 'is-danger' : ''">
                    <b-input  placeholder="Адрес постоянной регистрации*" v-model="addressReg"></b-input>
                  </b-field>
                </div>
                <div class="title">Адрес проживания</div>
                <div class='content'>
                  <b-field>
                    <b-checkbox v-model="addressSomeReg">Совпадает с адресом регистрации</b-checkbox>
                  </b-field>
                  <b-field v-if="!addressSomeReg" :type="$v.addressProp.$error ? 'is-danger' : ''">
                    <b-input placeholder="Адрес постоянной регистрации*" v-model="addressProp"></b-input>
                  </b-field>
                </div>
                <div class="title">Ежемесячный доход</div>
                <div class='content'>
                  <b-field>
                    <b-input placeholder="Ежемесячный доход*" v-model="monthlyIncome"></b-input>
                  </b-field>
                </div>
                <div class="title">Дополнительная информация</div>
                <div class='content'>
                  <b-field :type="$v.desc.$error ? 'is-danger' : ''">
                    <b-input placeholder="Адрес постоянной регистрации" v-model="desc"></b-input>
                  </b-field>
                </div>
              </section>
            </b-step-item>

            <b-step-item
              label="Завершение"
            >
              <div class="title">Загрузите сканы или фотографии документов</div>
              <section class="content">
                <div class="columns">
                  <div class="column">
                    <b-field>
                      <b-upload v-model="faceFiles"
                          multiple
                          drag-drop>
                          <section class="section">
                              <div class="content has-text-centered">
                                  <p>
                                      <b-icon
                                          icon="upload"
                                          size="is-large">
                                      </b-icon>
                                  </p>
                                  <p>Добавьте сюда своё фото сэлфи</p>
                              </div>
                          </section>
                      </b-upload>
                    </b-field>
                  </div>
                  <div class="column">
                    <div class="tags">
                      <span v-for="(file, index) in faceFiles"
                        :key="index"
                        class="tag is-primary" >
                        {{file.name}}
                        <button class="delete is-small"
                            type="button"
                            @click="deleteFaceFile(index)">
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </section>
              <section class="content">
                <div class="columns">
                  <div class="column">
                    <b-field>
                      <b-upload v-model="addFiles"
                          multiple
                          drag-drop>
                          <section class="section">
                              <div class="content has-text-centered">
                                  <p>
                                      <b-icon
                                          icon="upload"
                                          size="is-large">
                                      </b-icon>
                                  </p>
                                  <p>Добавьте сюда фото паспорта (страница с фото и прописка)</p>
                              </div>
                          </section>
                      </b-upload>
                    </b-field>
                  </div>
                  <div class="column">
                    <div class="tags">
                      <span v-for="(file, index) in addFiles"
                        :key="index"
                        class="tag is-primary" >
                        {{file.name}}
                        <button class="delete is-small"
                            type="button"
                            @click="deleteAddFile(index)">
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </b-step-item>
            <template
                #navigation="{previous, next}">
                <div class="padding">
                  <b-button
                      outlined
                      type="is-primary is-light"
                      icon-pack="fas"
                      icon-left="backward"
                      :disabled="previous.disabled || !assignOffert"
                      @click.prevent="setSlide(previous)">
                      Назад
                  </b-button>
                  <b-button
                      outlined
                      v-if="activeStep < 2"
                      type="is-primary is-light"
                      icon-pack="fas"
                      icon-right="forward"
                      :disabled="(activeStep == 1 && isProcess) || !assignOffert"
                      @click.prevent="setSlide(next)">
                      Далее
                  </b-button>
                  <b-button
                      v-if="activeStep == 2"
                      outlined
                      type="is-success"
                      icon-pack="fas"
                      icon-right="forward"
                      @click.prevent="sendForm">
                      Отправить
                  </b-button>
                </div>
            </template>
          </b-steps>
        </div>
        <div class="column is-one-thirds is-hidden-mobile">
          <div class="logo">
            <img src="/assets/logo.jpg"/>
          </div>
          <div class="summary">
            <b-field :label="'Общая сумма ' + typeLabel">{{ totalSum | numFormat }} руб.</b-field>
            <b-field v-if="program.type == 'credit'" label="Процентная ставка">{{ program.percent | numFormat }} %</b-field>
            <b-field label="Выбранный период">{{ period | yearFormat }}</b-field>
            <b-field label="Ежемесячный платеж">{{ paymentSum | numFormat }} руб.</b-field>
            <hr/>
            <div v-if="basket">
              <div class="columns" v-for="product in basket.products" :key="product.id">
                <div class="column">{{product.label}}:</div>
                <div class="column has-text-right">{{product.price * product.count | numFormat}} {{product.currency}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="isSended" class="steps">
      <b-message type="is-success" has-icon>
          <strong>Заявка отправлена.</strong>
          <br/>
          Спасибо. Ваша заявка отправлена, наш менеджер свяжется с Вами в ближайшее время.
        </b-message>
    </section>
  </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import MaskedInput from 'vue-masked-input'
import { ToastProgrammatic as Toast } from 'buefy'
import Axios from 'axios';


export default {
  name: 'Steps',
  props: {
    msg: String
  },
  data() {
    return {
      debug: process.env.VUE_APP_DEBUG == 1,
      programs: [],
      program: null,
      loaded: false,
      error: null,
      assignOffert: false,
      basket: null,
      store: null,
      uuid: '',
      activeStep: 0,
      totalSum: 100000,
      period: 1,
      name: '',
      phone: '',
      email: '',
      passport: '',
      birthday: null,
      birthplace: null,
      code: null,
      insurer: '',
      insureDate: null,
      addressReg: '',
      addressProp: '',
      addressSomeReg: false,
      monthlyIncome: null,
      desc: '',
      faceFiles: [],
      addFiles: [],
      smsCode: '',
      smsCodeValid: true,
      isProcess: false,
      isSended: false,
    }
  },
  methods: {
    yearFormat(value) {
      let base = value * 1
      if (value > 19) {
        base = value % 10
      }
      if (base == 1) {
        return value + ' месяц';
      } else if (base > 1 && base < 5) {
        return value + ' месяца';
      } else {
        return value + ' месяцев';
      }
    },
    deleteFaceFile(index) {
        this.faceFiles.splice(index, 1)
    },
    deleteAddFile(index) {
        this.addFiles.splice(index, 1)
    },
    setSlide(nav) {
      this.$v.$touch()
      // if (this.activeStep == 0) {
      //   if (!this.isProcess) {
      //     this.isProcess = true;
      //     if (!this.debug) {
      //       Axios
      //         .post('/api/v1/code?phone=' + this.phone)
      //     }
      //   }
      // }
      if (this.activeStep == 0) {
        this.$v.$reset()
      }
      if (this.$v.$invalid) {
        console.log('error!', this.$v)
      } else {
        nav.action()
      }
    },
    checkCode($event) {
      this.smsCodeValid = false;
      let code = $event.replace('_', '')
      if (code.length == 4) {
        if (this.debug && code == '0000') {
            this.isProcess = false;
            this.smsCodeValid = true;
        } else if (!this.debug) {
          Axios
          .get('/api/v1/code?phone=' + this.phone + '&code=' + code)
          .then((resp) => {
            if (resp.data.result) {
              this.isProcess = false;
              this.smsCodeValid = true;
            }
          });
        }
      }
    },
    sendForm() {
      const redirect_to = this.program.partner.redirect_to
      const formData = new FormData();
      formData.append("paymentSum", this.paymentSum);
      formData.append("program_id", this.program.id);
      formData.append("assignOffert", this.assignOffert);
      formData.append("uuid", this.uuid);
      formData.append("totalSum", this.totalSum);
      formData.append("period", this.period);
      formData.append("name", this.name);
      formData.append("phone", this.phone);
      formData.append("email", this.email);
      formData.append("passport", this.passport);
      formData.append("birthday", this.birthday.toISOString());
      formData.append("birthplace", this.birthplace);
      formData.append("code", this.code);
      formData.append("insurer", this.insurer);
      formData.append("insureDate", this.insureDate.toISOString());
      formData.append("addressReg", this.addressReg);
      formData.append("addressProp", this.addressSomeReg ? this.addressReg :  this.addressProp);
      formData.append("desc", this.desc);
      formData.append("monthlyIncome", this.monthlyIncome);
      let cnt_files = 0
      this.faceFiles.forEach((item, idx) => {
        formData.append('files[' + idx + ']', item)
        cnt_files++;
      })
      this.addFiles.forEach((item, idx) => {
        formData.append('files[' + (cnt_files + idx) + ']', item)
      })

      Axios
        .post(
          '/api/v1/send/' + this.uuid,
          formData,
          {headers: {
            'Content-Type': 'multipart/form-data'
          }}
        ).then((resp) => {
          if (resp.data.result) {
            if (redirect_to) {
              document.location.href = redirect_to
            } else {
              Toast.open('Спасибо. Заявка отправлена!');
            }
          } else {
            Toast.open('Заявка не отправлена!');
            this.error = 'Попоробуйте повторить позже.'
          }
          this.isSended = true;

        });
    }
  },
  watch: {
    monthlyPeriods(field) {
      this.period = field[0]
    }
  },
  computed: {
    paymentSum: function() {
      let pays = this.period;
      let percent = 0;
      if (this.program && this.program.type == 'credit') {
        percent = this.program.percent / 100 / 12;
      }

      let x = Math.pow(1 + percent, pays);
      let monthly = 0;
      if (this.program && this.program.type == 'credit') {
        monthly = (percent * x * this.totalSum) / (x - 1);
      } else {
        monthly = this.totalSum / pays
      }
      return Math.round(monthly * 100) / 100
    },
    typeLabel: function() {
      return this.program && this.program.type == 'credit' ? 'кредита' : 'рассрочки'
    },
    monthlyPeriods: function() {
      let periods = [];
      if (this.program && this.program.partner && this.program.partner.periods) {
        periods = this.program.periods ? this.program.periods : this.program.partner.periods;
        periods = periods.split(',').map(( item) => {
          if (item.indexOf('-') > -1) {
            const parts = item.split('-').map((i) => {return parseInt(i)})
            item = [...Array(parts[1] - parts[0] + 1).keys()].map(i => i + parts[0]);
          }
          return item
        })
        periods = periods.flat()
      }
      return periods.map((i) => {return parseInt(i)})
    }
  },
  validations: {
    name: {required,},
    phone: {required,},
    email: {},
    passport: {required: requiredIf(function() {
      return this.activeStep == 1
    })},
    birthday: {required: requiredIf(function() {
      return this.activeStep == 1
    })},
    birthplace: {required: requiredIf(function() {
      return this.activeStep == 1
    })},
    code: {required: requiredIf(function() {
      return this.activeStep == 1
    })},
    insureDate: {required: requiredIf(function() {
      return this.activeStep == 1
    })},
    insurer: {required: requiredIf(function() {
      return this.activeStep == 1
    })},
    addressReg: {required: requiredIf(function() {
      return this.activeStep == 1
    })},
    addressProp: {required: requiredIf(function() {
      return this.activeStep == 1 && !this.addressSomeReg
    })},
    monthlyIncome: {required: requiredIf(function() {
      return this.activeStep == 1
    })},
    desc: {},
  },
  components: {
    MaskedInput
  },
  mounted: function () {
    this.$nextTick(function () {
      let uri = window.location.href.split('?');
      if (uri.length == 2)
      {
        let vars = uri[1].split('&');
        let getVars = {};
        let tmp = '';
        vars.forEach(function(v){
          tmp = v.split('=');
          if(tmp.length == 2)
          getVars[tmp[0]] = tmp[1];
        });
        if (getVars.uuid != '') {
          this.totalSum = 0;
          this.uuid = getVars.uuid;
          this.loaded = false
          Axios
            .get('/api/v1/basket/' + this.uuid, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((resp) => {
              console.log(resp.data)
              if (resp.data.error) {
                this.error = resp.data.error;
                this.loaded = true
              } else {
                resp.data.data.basket.products.forEach((item) => {
                  this.totalSum += item.price * item.count;
                })
                this.basket = resp.data.data.basket;
                this.store = resp.data.data.store;
                let c = 1
                this.store.program.forEach((item) => {
                  Axios
                    .get('/api/v1/program/' + item, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then((resp) => {
                      if (resp.data.program.id > 0) {
                          if (!this.program) {
                            this.program = resp.data.program
                          }
                        this.programs.push(resp.data.program);
                      }
                      if (c == this.store.program.length) {
                        this.loaded = true
                      }
                      c++;
                    });
                });
              }
            });
        }
      }
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

label {
  color: #888 !important;
  font-size: 12px;
  font-weight: normal !important;
  padding: 10px 0;
}
.title {
  font-size: 16px !important;
  padding-left: 15px;
  color: #555;
}
.steps {
  max-width: 900px;
  margin: 0 auto;
}
.step div.content {
  max-width: 600px;
}
.step div.main {
  padding: 15px;
  border: 1px solid #efefef;
}
.contacts {
  padding: 10px 15px;
}

.summary {
  padding-left: 15px;
  /* margin-top: 90px */
}
.b-slider .b-slider-thumb-wrapper.has-indicator .b-slider-thumb {
  font-size: 10px;
  padding: 10px 7px !important;
  white-space: nowrap;
}
.padding {
  padding: 0px 30px;
}
.padding2 {
  padding: 0px 15px;
}
.sms-code {
  padding: 5px 10px;
  border: 1px solid #888888;
  width: 150px !important;
  border-radius: 3px;
}
.sms-code label{
  padding: 0px;
}
.sms-code input{
  width: 100px !important;
  border: 0px;
  font-size: 16px;
}
.sms-code input:focus {
    outline-width: 0;
}
.loader {
  font-size: 60px;
  margin: 0 auto;
}
.head-select .field-label label {
  width: 150px;
}
.head-select .field-label {
  padding-top: 0 !important;
}
.desc {
  font-size: 10px;
  color: #777;
}
.wraps .has-addons {
  flex-wrap: wrap;
}
.wraps .is-primary {
  color: white !important;
}
</style>
