import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import Vuelidate from 'vuelidate'
import Axios from 'axios';

Axios.defaults.baseURL = process.env.VUE_APP_SERVER_ADDRESS;

Vue.config.productionTip = false

Vue.use(Buefy)
Vue.use(Vuelidate)

Vue.filter('yearFormat', function(value) {
  let base = value * 1
  if (value > 19) {
    base = value % 10
  }
  if (base == 1) {
    return value + ' месяц';
  } else if (base > 1 && base < 5) {
    return value + ' месяца';
  } else {
    return value + ' месяцев';
  }
});

import numeral from 'numeral';
const numFormat = (numeral) => {
  /**
   * apply a Numeral.js formatting string to an input value
   * @param {Number} val input value to be changed
   * @param {String} [format='0,0'] Numeral.js format string
   * @return {String} formatted output string
   */
  return (val, format = '0,0.00') => numeral(val).format(format);
}
Vue.filter('numFormat', numFormat(numeral));

Vue.filter('toLabel', function(value) {
  const __mapper = {
    'credit': 'Кредит',
    'installment': 'Рассрочка',
    'annuity': 'Аннуитетный',
    'differentiated': 'Дифференцированный'
  }
  return __mapper[value] ? __mapper[value]: value
});

new Vue({
  render: h => h(App),
}).$mount('#app')
