<template>
  <div id="app">
    <Steps/>
  </div>
</template>

<script>
import Steps from './components/Steps.vue'

export default {
  name: 'App',
  components: {
    Steps
  }
} 
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 30px;
}
.logo {
  max-width: 900px;
  margin: 0 auto;
  padding-left: 30px;
  padding-bottom: 30px;
}
.logo img {
  width: 200px;
}
</style>
